import yup from '../../../../components/form/CustomYupValidator';
import { isDefined } from '../../../../services/utils';

const t = (key, attributes = {}) => I18n.t(`activerecord.errors.models.${key}`, attributes);

export const validationSchema = yup.object().shape({
  rad_license_item_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  activity_limit: yup
    .string()
    .nullable()
    .test(
      'activity_limit_exceeds_remaining_activity',
      t(
        'rad_license_item_assignment.attributes.activity_limit.cannot_be_more_than_remaining_activity',
      ),
      function _(activity_limit) {
        if (!isDefined(this.parent.remainingLicenseActivity)) return true;
        if (!isDefined(activity_limit)) return false;

        return Number(activity_limit) <= Number(this.parent.remainingLicenseActivity);
      },
    ),
  quantity_limit: yup
    .string()
    .nullable()
    .test(
      'quantity_limit_exceeds_remaining_quantity',
      t(
        'rad_license_item_assignment.attributes.quantity_limit.cannot_be_more_than_remaining_quantity',
      ),
      function _(quantity_limit) {
        if (!isDefined(this.parent.remainingLicenseQuantity)) return true;
        if (!isDefined(quantity_limit)) return false;

        return Number(quantity_limit) <= Number(this.parent.remainingLicenseQuantity);
      },
    ),
});
